import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography
} from "@mui/material";
import {HttpError, useCustomMutation, useList, useResource, useTranslate} from "@refinedev/core";
import {Sort} from "@mui/icons-material";
import {IContent} from "interfaces";
import {ReactSortable} from "react-sortablejs";
import {TransitionProps} from "@mui/material/transitions";
import {API_URL} from "../../constants";
import {grey} from "@mui/material/colors";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DisplaySort: React.FC = () => {
  const t = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [alertOpen, setAlertOpen] = React.useState(false);

  const handleClickOpen = () => setOpen(!open);
  const handleClickAlertOpen = () => setAlertOpen(!alertOpen);

  const {resource} = useResource();
  const {data} = useList<IContent, HttpError>({
    resource: resource?.name,
    filters: [
      {field: 'display', operator: 'eq', value: true}
    ],
    sorters: [
      {field: 'display', order: 'desc'},
      {field: 'sort', order: 'asc'},
    ],
  });

  const contents = useMemo(() => { return data?.data ?? [] }, [data?.data]);
  const [list, setList] = useState(contents);

  const fetch = useCallback(() => {
    if (list.length === 0){
      setList(contents);
    }
  }, [list, contents]);

  const onCloseHandler = () => {
    setOpen(false)
    setList(contents)
  }

  const { mutate } = useCustomMutation();
  const onSaveHandler = () => {
    const sorts = list.map((value, index) => {
      return {id: value.id, sort: index + 1}
    });

    mutate({
      url: `${API_URL}/location-content/update-sort`,
      method: "post",
      values: {
        updateLocationContents: sorts
      },
      successNotification: (data, values) => {
        return {
          message: t("", "순서가 변경되었습니다."),
          type: "success",
        };
      },
    }, {
      onSuccess: () => {
        setOpen(false);
        setAlertOpen(false);
      },
    });
  }

  useEffect(() => {
    void fetch();
  }, [fetch]);

  return (
      <>
        <Button
            variant="contained"
            color={"error"}
            onClick={handleClickOpen}
            startIcon={<Sort />}
            sx={{marginBottom: "5px"}}
        >
          {t("", "순서변경")}
        </Button>
        <Dialog
            open={open}
            onClose={onCloseHandler}
            aria-labelledby="display-sort-dialog-title"
            aria-describedby="display-sort-dialog-description"
            TransitionComponent={Transition}
            scroll={"paper"}
        >
          <DialogTitle id="display-sort-dialog-title">
            {t("", "콘텐츠 순서 변경")}
          </DialogTitle>
          <DialogContent>

            <ReactSortable
                list={list}
                setList={setList}
                animation={200}
                easing="ease-out"
            >
              {!!list && list.map((content, i) => (
                  <Card sx={{ display: 'flex', mb: "10px", cursor: "pointer", height: 150, backgroundColor: 'rgba(0, 0, 0, 0.45)' }} key={content.id}>
                    <Box sx={{ display: 'flex'  }}>
                      <CardMedia
                          component="img"
                          sx={{ width: 150 }}
                          image={content.filePath ?? ""}
                          alt={content.title ?? ""}
                      />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h6">
                          {content.title}&nbsp;
                          {content.day === 1 && `🔅`}
                          {content.day === 2 && `🌙`}
                          {content.day === 3 && `🔅🌙`}
                        </Typography>
                        <Typography variant="subtitle2" color="text.secondary" component="div"
                            sx={{
                                overflowWrap: "break-word",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 4,
                                WebkitBoxOrient: "vertical"
                            }}
                        >
                          {content.description}
                        </Typography>
                      </CardContent>
                      <Box
                          sx={{
                            position: 'absolute',
                            top: '30%',
                            left: '40%',
                            transform: 'translateX(-50%)',
                          }}
                      >
                        <Typography
                            variant={'h2'}
                            component={'h2'}
                            sx={{
                              width: "100%",
                              textAlign: "center",
                              fontWeight: 700,
                              color: grey[100],
                              backgroundColor: "none",
                            }}
                        >{i + 1}</Typography>
                      </Box>
                    </Box>
                  </Card>
              ))}
            </ReactSortable>

          </DialogContent>
          <DialogActions>
            <Button
                variant={"outlined"}
                color={"error"}
                onClick={onCloseHandler}>{t("buttons.cancel")}</Button>
            <Button
                variant={"contained"}
                onClick={handleClickAlertOpen} autoFocus>{t("buttons.save")}</Button>
          </DialogActions>
        </Dialog>


        <Dialog
            open={alertOpen}
            onClose={() => setAlertOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("", "저장 하시겠습니까?")}
          </DialogTitle>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
                variant={"outlined"}
                color={"error"}
                onClick={() => setAlertOpen(false)}>{t("buttons.cancel")}</Button>
            <Button
                variant={"contained"}
                autoFocus
                onClick={onSaveHandler} >{t("", "확인")}</Button>
          </DialogActions>
        </Dialog>

      </>
  )
};
