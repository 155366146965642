import React from "react";
import {useTranslate} from "@refinedev/core";
import {Chip, ChipProps} from "@mui/material";


type ApprovalStatusProps = {
  status?: "APRV_WAIT" | "APRV_REJ" | "APRV_COMPT";
}

export const AdministratorApprovalStatus: React.FC<ApprovalStatusProps> = ({ status }) => {
  const t = useTranslate();

  let color: ChipProps["color"];

  switch (status) {
    case "APRV_WAIT":
      color = "warning";
      break;
    case "APRV_COMPT":
      color = "primary";
      break;
    case "APRV_REJ":
      color = "error";
      break;
  }

  return (
      <Chip
          variant="outlined"
          size="small"
          color={color}
          label={t(`enum.admin.approvalStatus.${status}`)}
      />
  )
}