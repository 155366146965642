import React, {useEffect, useState} from "react";
import {
    Box,
    Paper,
    Popover,
    Stack,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {axiosInstance} from "@refinedev/simple-rest";
import {API_URL} from "../../constants";
import {DateRange} from "react-date-range";
import ko from 'date-fns/locale/ko';
import {addDays, subDays} from "date-fns"
import dayjs from "dayjs";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Bar, Line} from 'react-chartjs-2';
import {DateRangeOutlined} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useTranslate} from "@refinedev/core";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


export const DashboardPage: React.FC = () => {
    const t = useTranslate();
    const [analytics, setAnalytics] = useState([]);

    const [data, setData] = useState({labels: [], datasets: []});
    const [leftData, setLeftData] = useState({labels: [], datasets: []});
    const [likeData, setLikeData] = useState({labels: [], datasets: []});
    const [browser, setBrowser] = useState({labels: [], datasets: []});
    const [focusedDateRange, setFocusedDateRange] = useState<[number, number]>([0, 0]);
    const [state, setState] = useState([{
        startDate: addDays(new Date(), -7),
        endDate: addDays(new Date(), -1),
        key: "selection",
    },
    ])
    const lineOpt = (text: string) => {
        return {
            responsive: true,
            interaction: {mode: 'index' as const, intersect: false,},
            stacked: false,
            plugins: {
                legend: {position: 'bottom' as const,},
                title: {display: true, text: t('', text), position: 'bottom' as const,},
            },
            scales: {
                y: {type: 'linear' as const, display: true, position: 'left' as const,},
                y1: {type: 'linear' as const, display: true,
                    position: 'right' as const,
                    grid: {drawOnChartArea: false,},
                },
            },
        };
    };
    const barOpt = (text: string) => {
        return {
            indexAxis: 'y' as const,
            elements: {bar: {borderWidth: 2,},},
            responsive: true,
            plugins: {legend: {display: false,},
                title: {display: true, text: t('', text), position: 'bottom' as const,},
            },
        }
    };

    const map = new Map()
    map.set(0, {r: 255, g: 72, b: 72})
    map.set(1, {r: 255, g: 187, b: 0})
    map.set(2, {r: 172, g: 242, b: 0})
    map.set(3, {r: 0, g: 216, b: 255})
    map.set(4, {r: 109, g: 108, b: 255})
    map.set(5, {r: 255, g: 0, b: 221})
    map.set(6, {r: 229, g: 216, b: 92})
    map.set(7, {r: 153, g: 0, b: 76})

    const callAnalytics = async (startDate: string, endDate: string) => {

        try {
            const res = await axiosInstance.get(
                `${API_URL}/analytics?startDate=` + startDate + '&endDate=' + endDate,
                {
                    withCredentials: false,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                },
            );
            setAnalytics(res.data.analytics)
            const labels: string[] = [];
            const datasets: any[] = [];
            const likesets: any[] = [];
            res.data.analytics.forEach((a: { dimension: any, metric: any }) => {
                labels.push(a.dimension.title)
                datasets.push(a.metric.screenPageViews)
                likesets.push(a.metric.like)
            })
            const set = {
                labels,
                datasets: [
                    {
                        type: 'line' as const,
                        label: '좋아요',
                        borderColor: 'rgb(255, 99, 132)',
                        borderWidth: 2,
                        fill: false,
                        data: likesets,
                    },
                    {
                        label: '',
                        data: datasets,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },

                ],
            };
            // @ts-ignore
            setData(set)
            const resBrowser = await axiosInstance.get(
                `${API_URL}/analytics/browser?startDate=` + startDate + '&endDate=' + endDate,
                {
                    withCredentials: false,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                },
            );
            const browserLabels: string[] = [];
            const browserDatasets: any[] = [];

            resBrowser.data.analytics.forEach((a: { dimension: any, metric: any }) => {
                browserLabels.push(a.dimension.page)
                browserDatasets.push(a.metric.activeUsers)
            })
            const browserSet = {
                labels: browserLabels,
                datasets: [
                    {
                        label: '',
                        data: browserDatasets,
                        borderColor: 'rgb(71, 242, 121)',
                        backgroundColor: 'rgba(71, 242, 121, 0.5)',
                    },

                ],
            };
            // @ts-ignore
            setBrowser(browserSet)
            const resDate = await axiosInstance.get(
                `${API_URL}/analytics/date?startDate=` + startDate + '&endDate=' + endDate,
                {
                    withCredentials: false,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                    },
                },
            );
            const leftLabels = resDate.data.leftAnalytics.labels;
            const leftDatasets: any[] = [];
            let count = 0
            resDate.data.leftAnalytics.list.forEach((a: { title: any, view: any[] }) => {

                if (!map.get(count)) {
                    let r = Math.floor(Math.random() * (255))
                    let g = Math.floor(Math.random() * (255))
                    let b = Math.floor(Math.random() * (255))
                    map.set(count, {r, g, b})
                }
                const color = map.get(count)

                leftDatasets.push(
                    {
                        label: a.title,
                        data: a.view.map(item => item.screenPageView),
                        borderColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
                        backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 0.5)`,
                    }
                )
                count++
            })
            const lineData = {
                labels: leftLabels,
                datasets: leftDatasets
            };
            // @ts-ignore
            setLeftData(lineData)
            const likeLabels = resDate.data.leftAnalytics.labels;
            const likeDatasets: any[] = [];
            count = 0
            resDate.data.leftAnalytics.list.forEach((a: { title: any, view: any[] }) => {
                const color = map.get(count)
                likeDatasets.push(
                    {
                        label: a.title,
                        data: a.view.map(item => item.like),
                        borderColor: `rgb(${color.r}, ${color.g}, ${color.b})`,
                        backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, 0.5)`,
                    }
                )
                count++
            })

            const likeData = {
                labels: likeLabels,
                datasets: likeDatasets
            };
            // @ts-ignore
            setLikeData(likeData)

        } catch (error: any) {
            return { error }
        }
    }
    useEffect(() => {
        void callAnalytics(dayjs(state[0].startDate).format("YYYY-MM-DD"), dayjs(state[0].endDate).format("YYYY-MM-DD"));
        // eslint-disable-next-line
    }, []);


    const options = barOpt('페이지별 조회수 및 좋아요 카운트')

    const browserOptions = barOpt('브라우저별 사용자')

    const lineOptions = lineOpt('일자 기준 페이지별 조회수 ')

    const likeOptions = lineOpt('일자 기준 페이지별 좋아요 카운트')

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const expression = (userEngagementDuration: string, activeUser: string) => {

        if (userEngagementDuration && activeUser && Number(userEngagementDuration) > 0 && Number(activeUser) > 0) {
            const seconds = Math.floor(Number(userEngagementDuration) / Number(activeUser))
            const hour = Math.floor(seconds / 3600);
            const min = Math.floor((seconds % 3600) / 60);
            const sec = seconds % 60;
            return hour > 0 ? hour + '시 ' + min + '분 ' + sec + '초' : min + '분 ' + sec + '초'
        } else {
            return '0초'
        }
    };

    const open = Boolean(anchorEl);
    const calId = open ? 'date-range-picker-popover' : undefined;

    return (
        <Paper sx={{padding: 4}}>
            <Stack
                direction="row"
                gap={2}
                alignItems={"center"}
            >
                <TextField
                    size={"small"}
                    style={{width: "260px"}}
                    value={dayjs(state[0].startDate).format("YYYY-MM-DD") + ' ~ ' + dayjs(state[0].endDate).format("YYYY-MM-DD")}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={handleClick}>
                                <DateRangeOutlined/>
                            </IconButton>
                        )
                    }}
                />

            </Stack>
            <Popover
                id={calId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <DateRange
                    locale={ko}
                    editableDateInputs={true}
                    onChange={async (item: any) => {
                        setState([item.selection])
                        if (focusedDateRange[1] === 1) {
                            // setIsShowCalendar(false);
                            void callAnalytics(dayjs(item.selection.startDate).format("YYYY-MM-DD"), dayjs(item.selection.endDate).format("YYYY-MM-DD"));
                            void handleClose();
                        }
                    }}
                    moveRangeOnFirstSelection={false}
                    retainEndDateOnFirstSelection={false}
                    ranges={state}
                    months={2}
                    dateDisplayFormat={'yyyy-MM-dd'}
                    direction="horizontal"
                    showDateDisplay={false}
                    onRangeFocusChange={setFocusedDateRange}
                    maxDate={subDays(new Date(), 1)}
                />
            </Popover>

            <Stack spacing={4} sx={{pt: 2}}>
                <Stack direction="row">
                    <Box width={'50%'}> <Bar options={options} data={data}/></Box>
                    <Box width={'50%'}> <Bar options={browserOptions} data={browser}/></Box>
                </Stack>

                <Stack direction="row">
                    <Box width={'50%'}> <Line options={lineOptions} data={leftData}/></Box>
                    <Box width={'50%'}> <Line options={likeOptions} data={likeData}/></Box>
                </Stack>

                <TableContainer>
                    <Table sx={{minWidth: 1000}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("", "페이지 경로")}</TableCell>
                                <TableCell align="right">{t("", "타이틀")}</TableCell>
                                <TableCell align="right">{t("", "조회수")}</TableCell>
                                <TableCell align="right">{t("", "사용자")}</TableCell>
                                <TableCell align="right">{t("", "사용자당 조회수")}</TableCell>
                                <TableCell align="right">{t("", "평균 참여 시간")}</TableCell>
                                <TableCell align="right">{t("", "좋아요")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!!analytics &&
                                analytics.map((a: { dimension: any, metric: any }, i) => {
                                    return (
                                        <TableRow
                                            key={`${a.dimension.key}_${i}`}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">{a.dimension.page}</TableCell>
                                            <TableCell align="right">{a.dimension.title}</TableCell>
                                            <TableCell align="right">{a.metric.screenPageViews}</TableCell>
                                            <TableCell align="right">{a.metric.activeUsers}</TableCell>
                                            <TableCell align="right">{Number(a.metric.screenPageViewsPerUser).toFixed(2)}</TableCell>
                                            <TableCell align="right">{expression(a.metric.userEngagementDuration, a.metric.activeUsers)}</TableCell>
                                            <TableCell align="right">{a.metric.like}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Paper>
    )
}
