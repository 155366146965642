import {useTranslate} from "@refinedev/core";

export const API_URL = `${process.env.REACT_APP_API_URL}`
export const TOKEN_KEY = "noksapyeong-auth";

export const useArContentStepTitle = () => {
  const t = useTranslate();
  return [t("", "콘텐츠"), t("", "오브젝트"),]
}

export const REGEX_PASSWORD = /^[A-Za-z\d!@#$%^&*()]{8,30}$/;