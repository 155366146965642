import React, {useRef, useState} from "react";
import {UseStepsFormReturnType} from "@refinedev/react-hook-form";
import {HttpError, useTranslate} from "@refinedev/core";
import {
  Autocomplete,
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {Controller} from "react-hook-form";
import {IContent} from "interfaces";
import {red} from "@mui/material/colors";
import {AddPhotoAlternateOutlined, Close, Print} from "@mui/icons-material";
import ReactToPrint from "react-to-print";
import {fileUploader} from "../../pages/fnUtils";

export const ContentForm: React.FC<UseStepsFormReturnType<IContent, HttpError, IContent>> = ({
    refineCore: {onFinish, formLoading, queryResult},
    control,
    watch,
    register,
    setValue,
    formState: {errors},
    steps: {currentStep, gotoStep},
    setError,
}) => {

  const t = useTranslate();
  const content = queryResult?.data?.data ?? undefined;

  const [open, setOpen] = useState(false);

  const imageInput = watch("filePath");

  const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {

      const res = await fileUploader({event: event});

      setValue("filePath", res?.filePath, { shouldValidate: true, shouldDirty: true });

    } catch (error: any) {
      setError("filePath", { message: t(`errors.upload.${error?.name}`, `${error?.message}`) });
    }
  }

  const qrCodeRef = useRef(null);
  const onQrCodeClickHandler = () => setOpen(!open);

  return (
      <>
        <Grid
            container
            sx={{
              marginX: { xs: "0px" },
            }}
        >
          <Grid item xs={12} md={4}>
            <Stack
                gap={1}
                justifyContent="center"
                alignItems="center"
            >
              <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                      !!content?.qrFilePath &&
                      <Avatar
                          variant={"square"}
                          sx={{
                            width: 100,
                            height: 100,
                            border: `2px solid`,
                            cursor: 'pointer',
                            backgroundColor: "transparent",
                            "&:hover": {
                              'borderColor': red[400]
                            },
                            display: 'none'
                          }}
                          alt={content?.title + "_QR_CODE"}
                          src={content?.qrFilePath}
                          onClick={onQrCodeClickHandler}
                      />
                  }
              >
                <label htmlFor="content-image-input">
                  <Input
                      id="content-image-input"
                      type="file"
                      sx={{
                        display: "none",
                      }}
                      onChange={onChangeHandler}
                  />
                  <input
                      id="file"
                      {...register("filePath", {
                        required: t("errors.required.field", { field: t("", "콘텐츠 이미지") },),
                      })}
                      type="hidden"
                  />
                    <Avatar
                        variant="circular"
                        sx={{
                          cursor: "pointer",
                          width: 300,
                          height: 300,
                          backgroundColor: "transparent",
                          border: (errors.filePath) ? "1px dashed red" :"none"
                        }}
                        src={imageInput}
                        children={ <AddPhotoAlternateOutlined sx={{ fontSize: '100px' }} /> }
                        alt={t('', `콘텐츠 파일_${content?.title}`)}
                    />
                </label>
              </Badge>
              <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
              >
                {t("", "콘텐츠 파일")}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {t("", "(SVG, JPG, PNG, GIF or 3D model 최대 10MB)")}
              </Typography>
              {errors.filePath && (
                  <FormHelperText error>{errors.filePath.message}</FormHelperText>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7} paddingX={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormLabel
                      required
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                  >
                    {t("", "콘텐츠명")}
                  </FormLabel>
                  <TextField
                      {...register("title", {
                        required: t("errors.required.field", { field: t("", "콘텐츠명") },),
                      })}
                      size="small"
                      margin="none"
                      variant="outlined"
                  />
                  {errors.title && (
                      <FormHelperText error>{errors.title.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel
                      required
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                  >
                    {t("", "전시여부")}
                  </FormLabel>
                  <Controller
                      control={control}
                      name={"display"}
                      rules={{
                        // required: t("errors.required.field", { field: "전시여부" }),
                      }}
                      defaultValue={true}
                      render={({ field }) => (
                          <Autocomplete
                              size="small"
                              {...field}
                              disableClearable
                              getOptionLabel={(value) => {
                                const defaultValue = (value) ? '예' : '아니오'
                                return t('', `${defaultValue}`)
                              }}
                              onChange={(_, value,) => {
                                field.onChange(value,);
                              }}
                              options={[true, false]}
                              renderInput={(
                                  params,
                              ) => (
                                  <TextField
                                      {...params}
                                      variant="outlined"
                                      // error={!!errors.display}
                                      // required
                                  />
                              )}
                          />
                      )}
                  />
                  {errors.display && (
                      <FormHelperText error>{errors.display.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel
                      required
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                  >
                    {t("", "유형")}
                  </FormLabel>
                  <Controller
                    control={control}
                    name="type"
                    rules={{
                      required: t("errors.required.field", { field: t("", "유형") },),
                    }}
                    // eslint-disable-next-line
                    defaultValue={null as any}
                    render={({ field }) => (
                        <Autocomplete
                            size="small"
                            {...field}
                            disableClearable
                            onChange={(
                                _,
                                value,
                            ) => {
                              field.onChange(
                                  value,
                              );
                            }}
                            options={["A", "B", "C", "D", "E", "F", "G", "H"]}
                            getOptionLabel={(label) => t('', `Type-${label}`)}
                            renderInput={(
                                params,
                            ) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    error={!!errors.type}
                                    required
                                />
                            )}
                        />
                    )}
                  />
                  {errors.type && (
                      <FormHelperText error>{errors.type.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <FormLabel
                      required
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                  >
                    {t("", "이용 시간대")}
                  </FormLabel>
                  <Controller
                      control={control}
                      name='day'
                      rules={{
                        required: t("errors.required.field", { field: t("", "이용 시간대") },),
                      }}
                      // eslint-disable-next-line
                      defaultValue={1}
                      render={({ field }) => (
                          <Autocomplete
                              size="small"
                              {...field}
                              disableClearable
                              onChange={(
                                  _,
                                  value,
                              ) => {
                                field.onChange(
                                    value,
                                );
                              }}
                              options={[1, 2, 3]}
                              getOptionLabel={(label) => {
                                let name = "";
                                switch (label) {
                                  case 1:
                                    name = "낮"
                                    break;
                                  case 2:
                                    name = "밤"
                                    break;
                                  case 3:
                                    name = "낮과 밤"
                                    break;
                                }
                                return t('', `${name}`)
                              }}
                              renderInput={(
                                  params,
                              ) => (
                                  <TextField
                                      {...params}
                                      variant="outlined"
                                      error={!!errors.day}
                                      required
                                  />
                              )}
                          />
                      )}
                  />
                  {errors.day && (
                      <FormHelperText error>{errors.day.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs>
                <FormControl fullWidth>
                  <FormLabel
                      required
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                  >
                    {t("", "상세설명")}
                  </FormLabel>
                  <TextField
                      {...register("description", {
                        required: t("errors.required.field", { field: t("", "상세설명") },),
                      })}
                      margin="none"
                      variant="outlined"
                      multiline
                      minRows={5}
                      required
                      fullWidth
                  />
                  {errors.description && (
                      <FormHelperText error>{errors.description.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent ref={qrCodeRef}>
            <h1>{content?.title}</h1>
            <Avatar
                variant={"square"}
                sx={{
                  width: 500,
                  height: 500,
                  border: `2px solid`,
                  // cursor: 'pointer',
                  backgroundColor: "transparent",
                }}
                alt={content?.title + "_QR_CODE"}
                src={content?.qrFilePath}
            />
          </DialogContent>
          <DialogActions>
            <Button
                startIcon={<Close />}
                onClick={() => setOpen(false)}
            >{t("buttons.cancel")}</Button>
            <ReactToPrint
                trigger={() => <Button
                    startIcon={<Print />}
                    autoFocus
                >{t("", "인쇄")}</Button>}
                content={() => qrCodeRef?.current}
            />
          </DialogActions>
        </Dialog>

      </>
  )
}