import React, {useEffect, useState} from "react";
import {UseStepsFormReturnType} from "@refinedev/react-hook-form";
import {IAugmentedReality, IContent} from "interfaces";
import {HttpError, useDelete, useTranslate} from "@refinedev/core";
import {
  Autocomplete,
  Avatar,
  Badge, Box,
  Button, CardMedia,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import {AddPhotoAlternateOutlined, AddRounded, CloseOutlined, DeleteOutlined} from "@mui/icons-material";
import {Controller, useFieldArray} from "react-hook-form";
import {fileUploader} from "../../pages/fnUtils";
import '@google/model-viewer';


export const ObjectForm: React.FC<UseStepsFormReturnType<IContent, HttpError, IContent>> = ({
    refineCore: {onFinish, formLoading},
    control,
    register,
    getValues,
    setValue,
    formState: {errors},
    steps: {currentStep, gotoStep},
    setError
}) => {
  const t = useTranslate();

  const { update, remove } = useFieldArray({control, name: 'augmentedRealitys'})
  const augmentedRealityData = getValues('augmentedRealitys')
  const [augmentedRealityCount, setAugmentedRealityCount] = useState(0);

  const { mutate } = useDelete();

  const handleAddAugmentedReality = () => {
    setAugmentedRealityCount(prevState => prevState + 1)
    update(augmentedRealityCount, {} as IAugmentedReality)
  }

  const handleRemoveAugmentedReality = (arId: number, index: number) => {
    setAugmentedRealityCount(prevState => prevState - 1)
    remove(index)

    if(!!arId) {
      mutate({
        resource: "augmented-reality",
        id: arId,
        successNotification: false
      })
    }
  }

  const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>, i: number) => {
    try {
      const res = await fileUploader({event: event});

      if (!!res && !!res?.mimeType) {
        const mimeType = res?.mimeType;

        let objectFileType = "";
        if (mimeType?.includes("image")) {
          objectFileType = "IMAGE"
        }
        if (mimeType?.includes("model")) {
          objectFileType = "3D"
        }
        setValue(`augmentedRealitys.${i}.filePath`, res?.filePath, { shouldValidate: true, shouldDirty: true });
        setValue(`augmentedRealitys.${i}.type`, objectFileType as ("IMAGE" | "3D"), { shouldValidate: true, shouldDirty: true });
      }

    } catch (error: any) {
      setError(`augmentedRealitys.${i}.filePath`, { message: t(`errors.upload.${error?.name}`, `${error?.message}`) });
    }
  }

  useEffect(() => {
    if (!!augmentedRealityData) {
      setAugmentedRealityCount(augmentedRealityData.length);
    }
  }, [augmentedRealityData]);

  return (
      <>
        <Grid container
              sx={{
                marginX: { xs: "0px" },
              }}
              columnGap={2}
        >
          <Grid item xs={12} md={2}>
            <Button
                variant={'text'}
                startIcon={<AddRounded/>}
                onClick={handleAddAugmentedReality}
            >
              <Typography>{t('', '추가하기')}</Typography>
            </Button>
          </Grid>
          <Grid item xs>
            <Grid container columnSpacing={2}>
              <Stack divider={<Divider flexItem />} sx={{ width: '100%' }}>
                {!!augmentedRealityData &&
                    augmentedRealityData.map((a, i) => {
                      return(
                          <Grid item padding={4} xs={12} key={`key_` + i}>
                            <Grid container columnSpacing={2}>
                              <Grid item xs={4} >
                                <FormControl fullWidth>
                                  <Stack
                                      display="flex"
                                      alignItems="center"
                                      border={ (!a?.filePath) ? "1px dashed" : "none" }
                                      borderColor={ (errors.augmentedRealitys && errors.augmentedRealitys[i]?.filePath) ? "error.main" : "primary.main" }
                                      borderRadius="5px"
                                      padding="10px"
                                  >
                                    {a?.type === "3D"
                                        ?
                                        <Badge
                                            badgeContent={
                                              <>
                                                <Input
                                                    id={`object-images-input_${i}`}
                                                    type="file"
                                                    sx={{
                                                      display: "none",
                                                    }}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e, i)}
                                                />
                                                <input
                                                    id="file"
                                                    {...register(`augmentedRealitys.${i}.filePath`, {
                                                      required: t("errors.required.field", { field: t("", "오브젝트 파일") },),
                                                    })}
                                                    type="hidden"
                                                />
                                                <label htmlFor={`object-images-input_${i}`}>
                                                  <CloseOutlined sx={{ cursor: 'pointer' }} />
                                                </label>
                                              </>
                                            }
                                        >
                                          <Stack
                                              sx={{
                                                width: 180,
                                                height: 180
                                              }}
                                          >
                                            <model-viewer
                                                style={{width: "100%", height: "100%"}}
                                                src={a?.filePath}
                                                camera-controls={true}
                                                touch-action="pan-y"
                                                alt={t('', `오브젝트 파일_${a?.title}`)}
                                            />
                                          </Stack>
                                        </Badge>
                                        :
                                        <label htmlFor={`object-images-input_${i}`}>
                                          <Input
                                              id={`object-images-input_${i}`}
                                              type="file"
                                              sx={{
                                                display: "none",
                                              }}
                                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e, i)}
                                          />
                                          <input
                                              id="file"
                                              {...register(`augmentedRealitys.${i}.filePath`, {
                                                required: t("errors.required.field", { field: t("", "오브젝트 파일") },),
                                              })}
                                              type="hidden"
                                          />
                                          {!!a?.filePath
                                              ?
                                              <Box sx={{ display: 'flex'  }}>
                                                <CardMedia
                                                    component="img"
                                                    sx={{
                                                      cursor: "pointer",
                                                      width: 200,
                                                      backgroundColor: "transparent"
                                                    }}
                                                    image={a?.filePath ?? ""}
                                                    alt={t('', `오브젝트 파일_${a?.title}`)}
                                                />
                                              </Box>
                                              :
                                              <Avatar
                                                  variant="square"
                                                  sx={{
                                                    cursor: "pointer",
                                                    width: 200,
                                                    height: 240,
                                                    backgroundColor: "transparent",
                                                  }}
                                                  src={a?.filePath}
                                                  children={ <AddPhotoAlternateOutlined sx={{ fontSize: '100px' }} /> }
                                                  alt={t('', `오브젝트 파일_${a?.title}`)}
                                              />
                                          }
                                        </label>
                                    }
                                    <Typography
                                        variant="body2"
                                        style={{
                                          fontWeight: 800,
                                          marginTop: "8px",
                                        }}
                                    >
                                      {t("", `오브젝트 파일 ${i + 1}`)}
                                    </Typography>
                                    <Typography style={{ fontSize: "12px" }}>
                                      {t("", "(SVG, JPG, PNG, GIF or 3D model 최대 10MB)")}
                                    </Typography>
                                    {errors.augmentedRealitys && (
                                        <FormHelperText error>{errors.augmentedRealitys[i]?.filePath?.message}</FormHelperText>
                                    )}
                                  </Stack>
                                </FormControl>
                              </Grid>
                              <Grid item xs={5} >
                                <Stack rowGap={"14px"} >
                                  <FormControl fullWidth>
                                    <TextField
                                        {...register(`augmentedRealitys.${i}.title`, {
                                          required: t("errors.required.field", { field: t("", "오브젝트 명") },),
                                        })}
                                        variant={"outlined"}
                                        label={t("", "오브젝트 명")}
                                        size={"small"}
                                    />
                                    {errors.augmentedRealitys && (
                                        <FormHelperText error>{errors.augmentedRealitys[i]?.title?.message}</FormHelperText>
                                    )}
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <TextField
                                        {...register(`augmentedRealitys.${i}.description`, {
                                          required: t("errors.required.field", { field: t("", "상세설명") },),
                                        })}
                                        variant={"outlined"}
                                        label={t("", "상세설명")}
                                        size={"small"}
                                        multiline
                                        minRows={6}
                                    />
                                    {errors.augmentedRealitys && (
                                        <FormHelperText error>{errors.augmentedRealitys[i]?.description?.message}</FormHelperText>
                                    )}
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={`augmentedRealitys.${i}.display`}
                                        // rules={{
                                        //   required: t("errors.required.field", { field: "전시여부" }),
                                        // }}
                                        defaultValue={true}
                                        render={({ field }) => (
                                            <Autocomplete
                                                size="small"
                                                {...field}
                                                disableClearable
                                                getOptionLabel={(value) => {
                                                  const defaultValue = (value) ? '예' : '아니오'
                                                  return t('', `${defaultValue}`)
                                                }}
                                                onChange={(_, value,) => {
                                                  field.onChange(value,);
                                                }}
                                                options={[true, false]}
                                                renderInput={(
                                                    params,
                                                ) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={t("", "전시여부")}
                                                        required
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    {errors.augmentedRealitys && (
                                        <FormHelperText error>{errors.augmentedRealitys[i]?.display?.message}</FormHelperText>
                                    )}
                                  </FormControl>

                                  <FormControl fullWidth>
                                    <Controller
                                        control={control}
                                        name={`augmentedRealitys.${i}.type`}
                                        rules={{
                                          required: t("errors.required.field", { field: t("", "유형") },),
                                        }}
                                        // eslint-disable-next-line
                                        defaultValue={null as any}
                                        render={({ field }) => (
                                            <Autocomplete
                                                size="small"
                                                {...field}
                                                disableClearable
                                                readOnly
                                                onChange={(
                                                    _,
                                                    value,
                                                ) => {
                                                  field.onChange(
                                                      value,
                                                  );
                                                }}
                                                options={["IMAGE", "3D"]}
                                                getOptionLabel={(label) => t('', `${label}`)}
                                                renderInput={(
                                                    params,
                                                ) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label={t("", "유형")}
                                                        required
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                    {errors.augmentedRealitys && (
                                        //@ts-ignore
                                        <FormHelperText error>{errors.augmentedRealitys[i]?.type?.message}</FormHelperText>
                                    )}
                                  </FormControl>
                                </Stack>
                              </Grid>
                              <Grid item xs={2}>
                                <Stack
                                    sx={{
                                      width: '100%',
                                      height: '100%',
                                      alignItems: "flex-end",
                                      justifyContent: "flex-end",
                                    }}
                                >
                                  <Button
                                      variant={'text'}
                                      startIcon={<DeleteOutlined />}
                                      color={'error'}
                                      sx={{
                                        visibility: (!!a?.id) ? "hidden" : "visible"
                                      }}
                                      onClick={() => handleRemoveAugmentedReality(a?.id, i)}
                                  >
                                    <Typography>{t('', '삭제')}</Typography>
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </Grid>
                      )
                    })
                }
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </>
  )
}