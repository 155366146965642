import React from "react";
import {BaseKey, useNavigation} from "@refinedev/core";
import {IContent} from "interfaces";
import {Avatar, Badge, Box, Card, CardContent, CardHeader, CardMedia, Stack, Typography} from "@mui/material";
import {Favorite, FavoriteBorder} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

type ContentItemProps = {
  content: IContent;
  show?: (id: BaseKey) => void;
}

export const ContentItem: React.FC<ContentItemProps> = ({
  content,
  show
}) => {
  const { edit } = useNavigation();
  const { id, title, description, filePath, qrFilePath, display, day} = content;

  function getNumberFormat(number: number) {
    return new Intl.NumberFormat('ko-KR').format(number)
  }

  return (
      <Card
          variant='outlined'
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            height: "100%",
            boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)"
          }}
      >
        <CardHeader
            title={
              <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    width: 100,
                    color: "rgb(98, 98, 98)",
                    my: 1,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    }
                  }}
                  onClick={() => edit("location-content", id)}
              >{`#${id}`}</Typography>
            }
            // action={
            //   <Box component="div">
            //     <Box
            //         sx={{
            //           height: "24px",
            //           minWidth: "24px",
            //           borderRadius: "6px",
            //           alignItems: "center",
            //           justifyContent: "center",
            //           display: "inline-flex",
            //           padding: "0px 6px",
            //           fontSize: "0.75rem",
            //           fontWeight: 700,
            //           color: (display ? "rgb(17, 141, 87)" : "rgb(183, 29, 24)"),
            //           backgroundColor: (display ? "rgba(34, 197, 94, 0.16)" : "rgba(255, 86, 48, 0.16)"),
            //           top: "24px",
            //           right: "24px",
            //         }}
            //     >
            //       {display ? 'Show' : 'Hide'}
            //     </Box>
            //   </Box>
            // }
        />
        <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
        >
          <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <Avatar
                    variant={"square"}
                    sx={{
                      width: 64,
                      height: 64,
                      border: `2px solid`,
                      display: 'none'
                    }}
                    alt={title + "_QR_CODE"}
                    src={qrFilePath}
                />
              }
          >
            <CardMedia
                component="img"
                sx={{
                  width: { xs: 100, sm: 124, lg: 148, xl: 184 },
                  height: { xs: 100, sm: 124, lg: 148, xl: 184 },
                  borderRadius: "50%",
                }}
                alt={title}
                image={filePath}
            />
          </Badge>
        </Box>
        <CardContent
            sx={{
              paddingX: "36px",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
        >
          <Stack gap={"10px"}
              sx={{
                paddingTop: "10px"
              }}
          >
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: "18px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    color: "rgb(98, 98, 98)",
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    }
                  }}
                  onClick={() => edit("location-content", id)}
              >
                {title}&nbsp;
                {day === 1 && `🔅`}
                {day === 2 && `🌙`}
                {day === 3 && `🔅🌙`}
              </Typography>
              <Stack direction={"row"} gap={"10px"}>
                <Box
                    sx={{
                      height: "24px",
                      minWidth: "24px",
                      borderRadius: "6px",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "inline-flex",
                      padding: "0px 6px",
                      fontSize: "0.75rem",
                      fontWeight: 700,
                      color: (display ? "rgb(17, 141, 87)" : "rgb(183, 29, 24)"),
                      backgroundColor: (display ? "rgba(34, 197, 94, 0.16)" : "rgba(255, 86, 48, 0.16)"),
                      top: "24px",
                      right: "24px",
                    }}
                >
                  {display ? 'Show' : 'Hide'}
                </Box>
                <Tooltip title={getNumberFormat(content.like)} placement={"top-end"}>
                  {Number(content.like) > 0 ? <Favorite sx={{ color: '#FF8600' }} /> : <FavoriteBorder />}
                </Tooltip>
              </Stack>
            </Stack>
            <Typography
                variant="body2"
                sx={{
                  mt: 2,
                  overflowWrap: "break-word",
                  color: "text.secondary",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                  flex: 1,
                }}
            >
              {description}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
  )
}

