import React from "react";
import {HttpError, IResourceComponentsProps, ResourceRouterParams, useTranslate} from "@refinedev/core";
import {Edit} from "@refinedev/mui";
import {useForm} from "@refinedev/react-hook-form";
import {IAdministrator} from "interfaces";
import {Box, Tab} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useParams} from "react-router-dom";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {ProfileTabPanel, PasswordTabPanel} from "components/administrator";

export const AdministratorEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { id: editId } = useParams<ResourceRouterParams>();

  const editFormProps = useForm<
      IAdministrator,
      HttpError,
      IAdministrator
  >({
    refineCoreProps: {
      id: editId,
      action: 'edit',
      mutationMode: 'undoable'
    },
  });

  const {
    refineCore: {formLoading},
  } = editFormProps;

  const [tabValue, setTabValue] = React.useState('1');
  const onChangeTabHandler = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return(
      <Edit
          isLoading={formLoading}
          title={
            <Typography variant="h5">{t("", "관리자 수정")} {`#${editId}`}</Typography>
          }
          headerButtons={<></>}
          canDelete={false}
          footerButtons={<></>}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={onChangeTabHandler} aria-label="lab API tabs example">
                <Tab label={t("", "기본정보")} value="1" />
                <Tab label={t("", "비밀번호")} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ProfileTabPanel formProps={editFormProps} mode={'edit'} />
            </TabPanel>
            <TabPanel value="2">
              <PasswordTabPanel id={Number(editId)} />
            </TabPanel>
          </TabContext>
        </Box>
      </Edit>
  )
}