import React from "react";
import {HttpError, IResourceComponentsProps, useTranslate} from "@refinedev/core";
import {Create, SaveButton} from "@refinedev/mui";
import {Box, Button, Step, StepButton, Stepper, Typography} from "@mui/material";
import {useStepsForm} from "@refinedev/react-hook-form";
import {IContent} from "interfaces";
import {ContentForm, ObjectForm} from "components/content";
import {ArrowBackOutlined, ArrowForwardOutlined} from "@mui/icons-material";
import {useArContentStepTitle} from "../../constants";


export const ContentCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const stepTitles: any[] = useArContentStepTitle();

  const createStepFormProps = useStepsForm<IContent, HttpError, IContent>({
    refineCoreProps: {
      action: "create",
    },
    stepsProps: {
      isBackValidate: false,
    },
    warnWhenUnsavedChanges: true,
    defaultValues: {
      display: true,
      sort: 9999
    }
  });

  const {
    refineCore: {onFinish, formLoading},
    handleSubmit,
    steps: {currentStep, gotoStep},
  } = createStepFormProps;

  const renderFormByStep = (step: number) => {
    switch (step) {
      case 0:
        return <ContentForm {...createStepFormProps} />;
      case 1:
        return <ObjectForm {...createStepFormProps} />;
    }
  }

  return (
      <Create
          title={
            <Typography variant="h5">{t("", "콘텐츠 생성")}</Typography>
          }
          isLoading={formLoading}
          footerButtons={
            <>
              {currentStep > 0 && (
                  <Button
                      startIcon={<ArrowBackOutlined />}
                      onClick={() => {
                        gotoStep(currentStep - 1);
                      }}
                  >
                    {t("", "이전")}
                  </Button>
              )}
              {currentStep < stepTitles.length - 1 && (
                  <Button
                      endIcon={<ArrowForwardOutlined />}
                      onClick={() => gotoStep(currentStep + 1)}>
                    {t("", "다음")}
                  </Button>
              )}
              {currentStep === stepTitles.length - 1 && (
                  <SaveButton onClick={handleSubmit(onFinish)}/>
              )}
            </>
          }
      >
        <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            autoComplete="off"
        >
          <Stepper nonLinear activeStep={currentStep}>
            {stepTitles.map((label, index) => (
                <Step
                    key={label}
                    sx={{
                      "& .MuiStepLabel-label": {
                        fontSize: "18px",
                        lineHeight: "32px",
                      },
                    }}
                >
                  <StepButton onClick={() => gotoStep(index)}>
                    {label}
                  </StepButton>
                </Step>
            ))}
          </Stepper>
          <br/>
          {renderFormByStep(currentStep)}
        </Box>

      </Create>
  )
};