import React, {useMemo} from "react";
import {
  CrudFilters,
  getDefaultFilter,
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useTranslate
} from "@refinedev/core";
import {CreateButton, DateField, List, useDataGrid} from "@refinedev/mui";
import {IAdministrator, IAdministratorFilterVariables} from "interfaces";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useForm} from "@refinedev/react-hook-form";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem, Pagination,
  Select,
  TextField
} from "@mui/material";
import {Add, SearchOutlined} from "@mui/icons-material";
import {Controller} from "react-hook-form";
import {AdministratorApprovalStatus} from "components/administrator";
import Typography from "@mui/material/Typography";

export const AdministratorList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { edit } = useNavigation();

  const { dataGridProps, search, filters, current, pageCount, setCurrent} = useDataGrid<
      IAdministrator,
      HttpError,
      IAdministratorFilterVariables
  >({
    pagination: {
      pageSize: 10,
      mode: "client"
    },
    queryOptions: {
      retry: false
    },
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, approval } = params;

      filters.push({
        field: "q",
        operator: "contains",
        value: q !== "" ? q : undefined,
      });

      filters.push({
        field: "approval",
        operator: "eq",
        value: approval !== "" ? approval : undefined,
      });

      return filters;
    }
  });


  const columns = useMemo<GridColDef<IAdministrator>[]>(
      () => [
          {
            field: "avatar",
            headerName: t("", ""),
            renderCell: function render({ row }) {
              return <Avatar src={row.avatar ?? ""}/>;
            },
            flex: 1,
            sortable: false,
            align: "center",
            headerAlign: "center",
            maxWidth: 150
          },
          {
            field: "email",
            headerName: t("", "이메일"),
            flex: 1,
            sortable: false,
            align: "center",
            headerAlign: "center",
            renderCell: function ({ row }) {
              return (
                  <Typography
                      sx={{
                        cursor: 'pointer',
                        '&:hover': {
                          textDecoration: 'underline',
                          color: '#2196f3'
                        }
                      }}
                  >{row.email}</Typography>
              )
            }
          },
          {
            field: "name",
            headerName: t("", "이름"),
            flex: 1,
            sortable: false,
            align: "center",
            headerAlign: "center",
          },
          {
            field: "approvalStatus",
            headerName: t("", "승인"),
            sortable: false,
            align: "center",
            headerAlign: "center",
            renderCell: function ({ row }) {
              return <AdministratorApprovalStatus status={row.approvalStatus} />;
            },
            flex: 0.5,
          },
          {
            field: "createDate",
            headerName: t("", "등록일"),
            sortable: false,
            renderCell: function ({ row }) {
              return <DateField value={row.createDate} format="YYYY.MM.DD" />;
            },
            flex: 1,
            align: "center",
            headerAlign: "center",
          },
      ],
      [t],
  );

  const {
    register,
    handleSubmit,
    control,
  } = useForm<
      IAdministrator,
      HttpError,
      IAdministratorFilterVariables
  >({
    defaultValues: {
      q: getDefaultFilter("q", filters, "eq"),
      approval: getDefaultFilter("approval", filters, "eq") || "",
    },
    warnWhenUnsavedChanges: false
  });

  return (
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Card sx={{ paddingX: { xs: 2, md: 0 } }}>
            <CardHeader title={t("", "검색")} />
            <CardContent sx={{ pt: 0 }}>
              <Box
                  component="form"
                  sx={{ display: "flex", flexDirection: "column" }}
                  autoComplete="off"
                  onSubmit={handleSubmit(search)}
              >
                <TextField
                    {...register("q")}
                    label={t("", "")}
                    placeholder={t("", "이메일, 이름")}
                    margin="normal"
                    fullWidth
                    autoFocus
                    size="small"
                    InputProps={{
                      startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlined />
                          </InputAdornment>
                      ),
                    }}
                />
                <Controller
                    control={control}
                    name="approval"
                    render={({ field }: { field: any }) => (
                        <FormControl margin="normal" size="small">
                          <InputLabel id="approval-select">
                            {t("", "승인")}
                          </InputLabel>
                          <Select
                              {...field}
                              labelId="approval-select"
                              label={t("", "상태")}
                          >
                            <MenuItem value="">
                              {t("", "전체")}
                            </MenuItem>
                            <MenuItem value="APRV_WAIT">
                              {t("", "승인대기")}
                            </MenuItem>
                            <MenuItem value="APRV_COMPT">
                              {t("", "승인완료")}
                            </MenuItem>
                            <MenuItem value="APRV_REJ">
                              {t("", "승인거부")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                    )}
                />

                <Button type="submit" variant="contained">
                  {t("", "조회")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={9}>
          <List
              wrapperProps={{
                sx: { paddingX: { xs: 2, md: 0 } }
              }}
              headerButtonProps={{ pt: 1,  mr: 1 }}
              headerButtons={
                <CreateButton
                    startIcon={<Add />}
                    variant="outlined"
                    sx={{ marginBottom: "5px" }}
                />
              }
          >
            <DataGrid
                {...dataGridProps}
                columns={columns}
                filterModel={undefined}
                autoHeight
                rowHeight={60}
                loading={dataGridProps.loading}
                sortingMode={dataGridProps.sortingMode}
                sortModel={dataGridProps.sortModel}
                pagination={undefined}
                disableColumnMenu
                sx={{
                  ...dataGridProps.sx,
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                    visibility: "visible",
                  },
                  "& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
                    display: "none"
                  }
                }}
                onCellClick={( params) => {
                  if(params.field === "email") {
                    edit("admin", params.id);
                  }
                }}
                slots={{
                  pagination: () => (
                      <Pagination
                          count={pageCount}
                          page={current}
                          variant="outlined"
                          color="primary"
                          shape="rounded"
                          sx={{
                            display: "flex",
                            justifyContent: "end",
                            paddingY: "20px",
                          }}
                          onChange={(
                              event: React.ChangeEvent<unknown>,
                              page: number,
                          ) => {
                            event.preventDefault();
                            setCurrent(page);
                          }}
                      />
                  )
                }}
            />
          </List>
        </Grid>

      </Grid>
  )
}