import React, {useState} from "react";
import {Box, FormControl, FormHelperText, Grid, InputAdornment, Stack, TextField} from "@mui/material";
import {BaseKey, HttpError, useTranslate, useUpdate} from "@refinedev/core";
import {SaveOutlined, Visibility, VisibilityOff} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useForm} from "@refinedev/react-hook-form";
import {LoadingButton} from "@mui/lab";
import {IAdministratorPassword} from "interfaces";
import {REGEX_PASSWORD} from "../../constants";


type PasswordTabPanelProps = {
  id: BaseKey
}

export const PasswordTabPanel: React.FC<PasswordTabPanelProps> = ({
  id
}) => {
  const t = useTranslate();
  const [isShow, setIsShow] = useState(false);

  const {
    register,
    formState: {errors},
    handleSubmit,
    setError,
    reset
  } = useForm<
      IAdministratorPassword,
      HttpError,
      IAdministratorPassword
  >();

  const { mutate, isLoading } = useUpdate()


  const onSubmitHandler = (data: IAdministratorPassword) => {
    if(!data){
      return;
    }

    if(data.currentPassword === data.newPassword) {
      setError("newPassword", {
        type: "pattern",
        message: t("", "현재 비밀번호와 같습니다.")
      })
      return;
    }

    if(data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "pattern",
        message: t("", "입력하신 새 비밀번호가 다릅니다.")
      })
      return;
    }

    mutate({
      id: id,
      resource: "admin/update-password",
      values: {
        ...data
      },
      successNotification: (data, values, resource) => {
        reset();
        return {
          message: t("", "비밀번호가 변경되었습니다."),
          type: "success"
        }
      },
      errorNotification: (data, values, resource) => {
        return {
          message: `${data?.message}` || t("", "비밀번호 변경 중 오류가 발생했습니다."),
          type: "error",
        };
      },
    })
  }

  return (
      <Grid
          container
          marginTop="8px"
          sx={{
            marginX: { xs: "0px" },
            paddingX: { xs: 1, md: 4 },
          }}
      >
        <Grid mb={1} item xs={12} md={3}>

        </Grid>
        <Grid item xs={12} md={6}>
          <Stack gap="24px" component={"form"} onSubmit={handleSubmit(onSubmitHandler)}>
            <FormControl fullWidth>
              <TextField
                  {...register("currentPassword", {
                    required: t("errors.required.field",
                        { field: t("", "현재 비밀번호")})
                  })}
                  size="small"
                  margin="none"
                  variant="outlined"
                  label={t("", "현재 비밀번호")}
                  type={isShow ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setIsShow(!isShow)}>
                            {isShow ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    )
                  }}
              />
              {errors.currentPassword && (
                  <FormHelperText error>{errors.currentPassword.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                  {...register("newPassword", {
                    required: t("errors.required.field",
                        { field: t("", "새 비밀번호")}),
                    minLength: {
                      value: 8,
                      message: t("", "비밀번호는 8자 이상이어야 합니다.")
                    },
                    maxLength: {
                      value: 30,
                      message: t("", "비밀번호는 30자 이하이어야 합니다.")
                    },
                    pattern: {
                      value: REGEX_PASSWORD,
                      message: t("", "비밀번호는 영문/숫자/특수기호(!@#$%^&*()) 포함 8~30자 입니다.")
                    }
                  })}
                  size="small"
                  margin="none"
                  variant="outlined"
                  label={t("", "새 비밀번호")}
                  type={isShow ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setIsShow(!isShow)}>
                            {isShow ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    )
                  }}
              />
              {errors.newPassword && (
                  <FormHelperText error>{errors.newPassword.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                  {...register("confirmPassword", {
                    required: t("errors.required.field",
                        { field: t("", "새 비밀번호 재입력")})
                  })}
                  size="small"
                  margin="none"
                  variant="outlined"
                  label={t("", "새 비밀번호 재입력")}
                  type={isShow ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setIsShow(!isShow)}>
                            {isShow ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                    )
                  }}
              />
              {errors.confirmPassword && (
                  <FormHelperText error>{errors.confirmPassword.message}</FormHelperText>
              )}
            </FormControl>
            <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
            >
              <LoadingButton
                  variant={"contained"}
                  type={"submit"}
                  loading={isLoading}
                  startIcon={<SaveOutlined />}
                  sx={{ minWidth: 0 }}
              >
                {t("buttons.save")}
              </LoadingButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
  )
}