import {AuthPage, ThemedTitleV2} from "@refinedev/mui";
import {useTranslate} from "@refinedev/core";
import React from "react";

export const Login: React.FC = () => {
  const t = useTranslate();

  return (
    <AuthPage
      type="login"
      formProps={{
        defaultValues: { email: "", password: "" },
      }}
      title={
      <ThemedTitleV2
          collapsed={false}
          wrapperStyles={{
            gap: "8px",
          }}
          text={t('', '녹사평 AR Bridge')} />
      }
      forgotPasswordLink
      rememberMe
      registerLink
    />
  );
};
