import React from "react";
import {ThemedTitleV2} from "@refinedev/mui";
import {useTranslate} from "@refinedev/core";

type TitleProps = {
  collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
  const t = useTranslate();
  return (
      <ThemedTitleV2
          collapsed={collapsed}
          text={t('', '녹사평 AR Bridge 관리자')}
      />
  )
};