import React from "react";
import {axiosInstance} from "@refinedev/simple-rest";
import {API_URL} from "../constants";
import * as mime from 'react-native-mime-types';

interface FileUploaderProps {
  event: React.ChangeEvent<HTMLInputElement>,
  options? : {
    maxSize?: number
    allowedFileTypes?: string[]
  }
}
export const fileUploader = async ({event, options}: FileUploaderProps) => {
  const limit = options?.maxSize || 1024 * 1024 * 10;

  const target = event.target;
  const file: File = (target.files as FileList)[0];
  const error = new Error();

  if (!file) {
    return;
  }

  const mimeType = mime.contentType(file.name) as string ?? "";

  if(!!options?.allowedFileTypes && options?.allowedFileTypes?.length > 0) {

    const allowed = options?.allowedFileTypes.every((f) => file.type.includes(f));
    if (!allowed) {
      error.name = "type";
      throw error;
    }

  } else {
    if(!(mimeType.includes("image") || mimeType.includes("model"))) {
      error.name = "type";
      throw error;
    }
  }

  if(file.size > limit) {
    error.name = "size";
    throw error;
  }

  const formData = new FormData();
  formData.append("file", file);

  const res = await axiosInstance.post<{ filePath: string }>(
      `${API_URL}/upload`,
      formData,
      {
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      },
  );

  return {
    filePath: res.data?.filePath,
    mimeType: mimeType,
  };
}