import React from "react";
import {HttpError, IResourceComponentsProps, useTranslate} from "@refinedev/core";
import {Create} from "@refinedev/mui";
import {useForm} from "@refinedev/react-hook-form";
import {IAdministrator} from "interfaces";
import {Typography} from "@mui/material";
import {ProfileTabPanel} from "../../components/administrator";

export const AdministratorCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const createFormProps = useForm<
      IAdministrator,
      HttpError,
      IAdministrator
  >({
    refineCoreProps: {
      action: 'create',
    }
  });

  const {
    refineCore: {formLoading},
  } = createFormProps;

  return (
      <Create
          isLoading={formLoading}
          title={
            <Typography variant="h5">{t("", "관리자 생성")}</Typography>
          }
          footerButtons={<></>}
      >
        <ProfileTabPanel formProps={createFormProps} mode={'create'} />
      </Create>
  )
}