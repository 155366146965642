import {Authenticated, Refine} from "@refinedev/core";
import {RefineKbar, RefineKbarProvider} from "@refinedev/kbar";

import {ErrorComponent, notificationProvider, RefineSnackbarProvider, ThemedLayoutV2,} from "@refinedev/mui";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import {ForgotPassword} from "pages/forgotPassword";
import {Login} from "pages/login";
import {Register} from "pages/register";
import {useTranslation} from "react-i18next";
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import {authProvider} from "./authProvider";
import {Header} from "components";
import {ColorModeContextProvider} from "./contexts/color-mode";
import {ContentCreate, ContentEdit, ContentList} from "pages/contents";
import {InsightsSharp, PeopleAltOutlined, QrCode2} from "@mui/icons-material";
import {AdministratorCreate, AdministratorEdit, AdministratorList} from "pages/administrators";
import {Sider, Title} from "components/layout";
import {API_URL} from "./constants";
import {DashboardPage} from "./pages/dashboard";
import {SessionTimer} from "./components/session";

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <Refine
                dataProvider={{
                  default: dataProvider(API_URL)
                }}
                notificationProvider={notificationProvider}
                routerProvider={routerBindings}
                authProvider={authProvider}
                i18nProvider={i18nProvider}
                resources={[
                  {
                    name: "dashboard",
                    list: "/",
                    meta: {
                      icon: <InsightsSharp />,
                    },
                  },
                  {
                    name: "location-content",
                    list: "/contents",
                    create: "/contents/create",
                    edit: "/contents/edit/:id",
                    meta: {
                      canDelete: true,
                      icon: <QrCode2 />
                    }
                  },
                  {
                    name: "admin",
                    list: '/administrators',
                    create: '/administrators/create',
                    edit: '/administrators/edit/:id',
                    meta: {
                      canDelete: true,
                      icon: <PeopleAltOutlined />
                    }
                  },
                ]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true,
                  reactQuery: {
                    devtoolConfig: false
                  }
                }}
            >
              <Routes>
                <Route
                    element={
                      <Authenticated fallback={<CatchAllNavigate to="/login" />} v3LegacyAuthProviderCompatible>
                        <ThemedLayoutV2
                            Header={() => <Header />}
                            Title={Title}
                            Sider={Sider}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                >
                  <Route
                      index
                      element={<DashboardPage />}
                  />
                  <Route path="/contents">
                    <Route index element={<ContentList />} />
                    <Route path="create" element={<ContentCreate />} />
                    <Route path="edit/:id" element={<ContentEdit />} />
                  </Route>
                  <Route path="/administrators">
                    <Route index element={<AdministratorList />} />
                    <Route path="create" element={<AdministratorCreate />} />
                    <Route path="edit/:id" element={<AdministratorEdit />}/>
                  </Route>
                  <Route path="*" element={<ErrorComponent />} />
                </Route>
                <Route
                    element={
                      <Authenticated fallback={<Outlet />} v3LegacyAuthProviderCompatible>
                        <NavigateToResource resource="dashboard" />
                      </Authenticated>
                    }
                >
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                </Route>
              </Routes>

              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler />
              <SessionTimer />
            </Refine>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
