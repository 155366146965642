import React from "react";
import {HttpError, IResourceComponentsProps, ResourceRouterParams, useNavigation, useTranslate} from "@refinedev/core";
import {DeleteButton, Edit, SaveButton} from "@refinedev/mui";
import {Box, Button, Step, StepButton, Stepper, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {useStepsForm} from "@refinedev/react-hook-form";
import {IContent} from "interfaces";
import {ArrowBackOutlined, ArrowForwardOutlined} from "@mui/icons-material";
import {ContentForm, ObjectForm} from "components/content";
import {useArContentStepTitle} from "../../constants";

export const ContentEdit: React.FC<IResourceComponentsProps> = () => {
  const { id: editId } = useParams<ResourceRouterParams>();

  const t = useTranslate();
  const { list } = useNavigation();
  const stepTitles: any[] = useArContentStepTitle();

  const editStepFormProps = useStepsForm<IContent, HttpError, IContent>({
    refineCoreProps: {
      id: editId,
      action: "edit",
      mutationMode: "undoable"
    },
    stepsProps: {
      isBackValidate: false,
    },
    warnWhenUnsavedChanges: true,
  });

  const {
    refineCore: {onFinish, formLoading},
    handleSubmit,
    steps: {currentStep, gotoStep},
  } = editStepFormProps;

  const renderFormByStep = (step: number) => {
    switch (step) {
      case 0:
        return <ContentForm {...editStepFormProps} />;
      case 1:
        return <ObjectForm {...editStepFormProps} />;
    }
  }

  return (
      <Edit
          title={
            <Typography variant="h5">{t("", "콘텐츠 수정")} {`#${editId}`}</Typography>
          }
          isLoading={formLoading}
          headerButtons={
            <DeleteButton
                recordItemId={editId}
                confirmTitle={t("", "삭제하시겠습니까?")}
                onSuccess={() => list("location-content")}
            />
          }
          footerButtons={
            <>
              {currentStep > 0 && (
                  <Button
                      startIcon={<ArrowBackOutlined />}
                      onClick={() => {
                        gotoStep(currentStep - 1);
                      }}
                  >
                    {t("", "이전")}
                  </Button>
              )}
              {currentStep < stepTitles.length - 1 && (
                  <Button
                      endIcon={<ArrowForwardOutlined />}
                      onClick={() => gotoStep(currentStep + 1)}>
                    {t("", "다음")}
                  </Button>
              )}
              {currentStep === stepTitles.length - 1 && (
                  <SaveButton onClick={handleSubmit(onFinish)}/>
              )}
            </>
          }
      >
        <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            autoComplete="off"
        >
          <Stepper nonLinear activeStep={currentStep}>
            {stepTitles.map((label, index) => (
                <Step
                    key={label}
                    sx={{
                      "& .MuiStepLabel-label": {
                        fontSize: "18px",
                        lineHeight: "32px",
                      },
                    }}
                >
                  <StepButton onClick={() => gotoStep(index)}>
                    {label}
                  </StepButton>
                </Step>
            ))}
          </Stepper>
          <br/>
          {renderFormByStep(currentStep)}
        </Box>
      </Edit>
  )
}