import React, {useEffect, useState} from "react";
import {useGetIdentity, useLogout, useTranslate} from "@refinedev/core";
import {useTimer} from "react-timer-hook";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import jwt_decode from "jwt-decode";
import {TOKEN_KEY} from "../../constants";

export const SessionTimer: React.FC = () => {
  const t = useTranslate();
  const { data: user } = useGetIdentity();
  const { mutate: logout } = useLogout();

  const {
    restart,
    pause,
    isRunning
  } = useTimer({
    expiryTimestamp: new Date(),
    onExpire: () => {
      if(isRunning) {
        setOpen(true)
      }
    },
    autoStart: false
  });

  const [open, setOpen] = useState(false);
  const onCloseHandler = () => {
    setOpen(false);
    logout();
  }

  useEffect(() => {

    if (user) {
      const token = JSON.parse(localStorage.getItem(TOKEN_KEY) ?? 'null');
      const access_token: any = jwt_decode(token.access_token);
      const time = new Date(access_token.exp * 1000);
      time.setSeconds(time.getSeconds() - 60 * 10);

      restart(time);
    } else {
      pause();
    }

  }, [user, restart, pause]);

  return (
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            {t('', '세션이 만료되었습니다. 다시 로그인하세요.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseHandler}>{t('', '확인')}</Button>
        </DialogActions>
      </Dialog>
  );
}