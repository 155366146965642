import DarkModeOutlined from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlined from "@mui/icons-material/LightModeOutlined";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {useGetIdentity, useList, useTranslate} from "@refinedev/core";
import {HamburgerMenu, RefineThemedLayoutV2HeaderProps} from "@refinedev/mui";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {ColorModeContext} from "../../../contexts/color-mode";
import {IAdministrator, IContent, IUser} from "interfaces";
import {Autocomplete, Box, Link, TextField} from "@mui/material";
import {SearchOutlined} from "@mui/icons-material";

interface IOptions {
  label: string;
  url: string;
  link: string;
  category: string;
}

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = () => {
  const [filterValue, setFilterValue] = useState("");
  const [options, setOptions] = useState<IOptions[]>([]);

  const { mode, setMode } = useContext(ColorModeContext);
  const { data: user } = useGetIdentity<IUser | null>();
  const t = useTranslate();

  const { refetch: refetchContents, data: contentData } = useList<IContent>({
    resource: "location-content",
    filters: [{ field: "q", operator: "contains", value: filterValue }],
    queryOptions: {
      enabled: false,
      retry: false,
    }
  });

  const { refetch: refetchAdmins, data: adminData } = useList<IAdministrator>({
    resource: "admin",
    filters: [{field: "q", operator: "contains", value: filterValue}],
    queryOptions: {
      enabled: false,
      retry: false,
    }
  });

  const searchHandler = useCallback(() => {
    setOptions([]);
    void refetchContents();
    void refetchAdmins();
  }, [refetchContents, refetchAdmins]);

  useEffect(() => {
    void searchHandler();
  }, [filterValue, searchHandler]);

  useEffect(() => {
    if(!!contentData) {
      const contentOptionGroup = contentData?.data.map((value) => {
        return {
          label: `${value.title}`,
          url: `${value.filePath}`,
          link: `/contents/edit/${value.id}`,
          category: t("", "콘텐츠")
        }
      });

      if (contentOptionGroup.length > 0) {
        setOptions((prevOptions) => [
          ...prevOptions,
          ...contentOptionGroup,
        ]);
      }
    }
  }, [contentData, t]);

  useEffect(() => {
    if(!!adminData) {
      const adminOptionGroup = adminData?.data.map((value) => {
        return {
          label: `${value.email} / ${value.name}`,
          url: `${value.avatar}`,
          link: `/administrators/edit/${value.id}`,
          category: t("", "관리자")
        }
      });

      if (adminOptionGroup.length > 0) {
        setOptions((prevOptions) => [
          ...prevOptions,
          ...adminOptionGroup,
        ]);
      }
    }
  }, [adminData, t])

  return (
    <AppBar color="default" position={"sticky"} sx={{ backgroundColor: (mode === 'light') ? 'white' : 'black' }}>
      <Toolbar
          sx={{
            paddingLeft: {
              sm: "24px",
              md: "24px",
            },
          }}
      >
        <HamburgerMenu />

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" flex={1}>
            <Autocomplete
                sx={{
                  maxWidth: 550,
                }}
                id="search-autocomplete"
                options={options}
                filterOptions={(x) => x}
                disableClearable
                freeSolo
                fullWidth
                size="small"
                onInputChange={(event, value) => {
                  if (event?.type === "change") {
                    setFilterValue(value);
                  }
                }}
                groupBy={(option) => option.category}
                renderOption={(props, option: IOptions) => {
                  return (
                      <Link href={option.link} >
                        <Box
                            {...props}
                            component="li"
                            sx={{
                              display: "flex",
                              padding: "10px",
                              alignItems: "center",
                              gap: "10px",
                            }}
                        >
                          <Avatar
                              sx={{
                                width: {
                                  sm: "48px",
                                  md: "54px",
                                  lg: "64px",
                                },
                                height: {
                                  sm: "48px",
                                  md: "54px",
                                  lg: "64px",
                                },
                              }}
                              src={option.url}
                          />
                          <Typography
                              sx={{
                                fontSize: {
                                  md: "14px",
                                  lg: "16px",
                                },
                              }}
                          >
                            {option.label}
                          </Typography>
                        </Box>
                      </Link>
                  );
                }}
                renderInput={(params) => {
                  return (
                      <Box
                          position="relative"
                          sx={{
                            display: {
                              xs: "none",
                              sm: "block",
                            },
                          }}
                      >
                        <TextField
                            {...params}
                            label={t("", "키워드 검색")}
                            InputProps={{
                              ...params.InputProps,
                            }}
                        />
                        <IconButton
                            sx={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              "&:hover": {
                                backgroundColor:
                                    "transparent",
                              },
                            }}
                        >
                          <SearchOutlined />
                        </IconButton>
                      </Box>
                  );
                }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" gap={'12px'}>
            <IconButton
                color="inherit"
                onClick={() => {
                  setMode();
                }}
            >
              {mode === "dark" ? <LightModeOutlined /> : <DarkModeOutlined />}
            </IconButton>
            <Stack
                direction="row"
                gap="12px"
                alignItems="center"
                justifyContent="center"
            >
              <Typography variant="subtitle2">
                {user?.name}
              </Typography>
              <Avatar src={user?.avatar} alt={user?.name} />
            </Stack>
          </Stack>
        </Stack>

      </Toolbar>
    </AppBar>
  );
};
