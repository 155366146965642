import React, {useCallback, useEffect, useState} from "react";
import {UseFormReturnType} from "@refinedev/react-hook-form";
import {IAdministrator, IUser} from "interfaces";
import {HttpError, useGetIdentity, useTranslate} from "@refinedev/core";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input, InputAdornment,
  Stack,
  TextField,
  Typography,
  IconButton
} from "@mui/material";
import {Controller} from "react-hook-form";
import {SaveButton} from "@refinedev/mui";
import {Person, Visibility, VisibilityOff} from "@mui/icons-material";
import {axiosInstance} from "@refinedev/simple-rest";
import {API_URL, REGEX_PASSWORD, TOKEN_KEY} from "../../constants";
import {fileUploader} from "../../pages/fnUtils";

interface ProfileTabPanelProps {
  formProps: UseFormReturnType<IAdministrator, HttpError, IAdministrator>,
  mode: string
}

export const ProfileTabPanel: React.FC<ProfileTabPanelProps> = ({
    formProps,
    mode
}) => {

  const {
    refineCore: {mutationResult, id: editId},
    control,
    watch,
    register,
    setValue,
    formState: {errors, isLoading},
    saveButtonProps,
    setError
  } = formProps;

  const t = useTranslate();
  const { data: user } = useGetIdentity<IUser>();
  const sessionId = user?.id;
  const avatarInput = watch("avatar");

  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);

  const onChangeHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const res = await fileUploader({event: event, options: { allowedFileTypes: ['image'] }});
      setValue("avatar", res?.filePath, { shouldValidate: true, shouldDirty: true });
      setIsUploadLoading(false);
    } catch (error: any) {
      setError("avatar", { message: t(`errors.upload.${error?.name}`, `${error?.message}`) });
      setIsUploadLoading(false);
    }
  }

  const fetchAdminSessionHandler = useCallback(async () => {
    if (mutationResult.isSuccess && sessionId === Number(editId)) {
      const {data, status} = await axiosInstance.get(API_URL + `/admin/${editId}`);

      if (status === 200 && data) {
        const token = JSON.parse(localStorage.getItem(TOKEN_KEY) ?? 'null');
        const newToken = {
          ...token,
          name: data.name,
          avatar: data.avatar
        }
        localStorage.setItem(TOKEN_KEY, JSON.stringify(newToken));
      }
    }
  }, [mutationResult.isSuccess, sessionId, editId]);

  useEffect(() => {
    void fetchAdminSessionHandler();
  }, [fetchAdminSessionHandler]);

  return (
      <>
          <Grid
              container
              marginTop="8px"
              sx={{
                marginX: { xs: "0px" },
                paddingX: { xs: 1, md: 4 },
              }}
          >
            <Grid mb={1} item xs={12} md={4}>
              <Stack
                  gap={1}
                  justifyContent="center"
                  alignItems="center"
              >
                <label htmlFor="aadmin-image-input">
                  <Input
                      id="aadmin-image-input"
                      type="file"
                      sx={{
                        display: "none",
                      }}
                      onChange={onChangeHandler}
                  />
                  <input
                      id="file"
                      {...register("avatar")}
                      type="hidden"
                  />
                  <Avatar
                      variant="circular"
                      sx={{
                        cursor: "pointer",
                        width: 180,
                        height: 180,
                        // backgroundColor: "transparent",
                        // border: (errors.filePath) ? "1px solid red" :"none"
                      }}
                      src={avatarInput}
                      children={
                        isUploadLoading ?
                            <Box sx={{ display: 'flex' }}>
                              <CircularProgress />
                            </Box>
                            :
                            <Person sx={{ fontSize: '150px' }} />
                      }
                      alt={t('', `프로필 이미지`)}
                  />
                </label>

                <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                >
                  {t("", "프로필 이미지")}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {t("", "(SVG, JPG, PNG, GIF 최대 10MB)")}
                </Typography>
                {errors.avatar && (
                    <FormHelperText error>{errors.avatar.message}</FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack gap="24px">
                <FormControl>
                  <FormLabel
                      required
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                  >
                    {t("", "이름")}
                  </FormLabel>
                  <TextField
                      {...register("name", {
                        required: t(
                            "errors.required.field",
                            {
                              field: t("", "이름"),
                            },
                        ),
                      })}
                      size="small"
                      margin="none"
                      variant="outlined"
                  />
                  {errors.name && (
                      <FormHelperText error>{errors.name.message}</FormHelperText>
                  )}
                </FormControl>
                <FormControl>
                  <FormLabel
                      required
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                  >
                    {t("", "이메일")}
                  </FormLabel>
                  <TextField
                      {...register("email", {
                        required: t(
                            "errors.required.field",
                            { field: t("", "이메일")},
                        ),
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: t("", "유효한 이메일 형식이 아닙니다."),
                        },
                      })}
                      size="small"
                      margin="none"
                      variant="outlined"
                      InputProps={{
                        readOnly: (mode === 'edit'),
                        disabled: (mode === 'edit')
                      }}
                  />
                  {errors.email && (
                      <FormHelperText error>{errors.email.message}</FormHelperText>
                  )}
                </FormControl>
                {mode === 'create'
                  &&
                    <>
                      <FormControl>
                        <FormLabel
                            required
                            sx={{
                              marginBottom: "8px",
                              fontWeight: "700",
                              fontSize: "14px",
                              color: "text.primary",
                            }}
                        >
                          {t("", "비밀번호")}
                        </FormLabel>
                        <TextField
                            {...register("password", {
                              required: t("errors.required.field",
                                  { field: t("", "비밀번호")}),
                              minLength: {
                                value: 8,
                                message: t("", "비밀번호는 8자 이상이어야 합니다.")
                              },
                              maxLength: {
                                value: 30,
                                message: t("", "비밀번호는 30자 이하이어야 합니다.")
                              },
                              pattern: {
                                value: REGEX_PASSWORD,
                                message: t("", "비밀번호는 영문/숫자/특수기호(!@#$%^&*()) 포함 8~30자 입니다.")
                              }
                            })}
                            size="small"
                            margin="none"
                            variant="outlined"
                            type={isShow ? 'text' : 'password'}
                            InputProps={{
                              endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => setIsShow(!isShow)}>
                                      {isShow ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                              )
                            }}
                        />
                        {errors.password && (
                            <FormHelperText error>{errors.password.message}</FormHelperText>
                        )}
                      </FormControl>
                    </>
                }
                <FormControl>
                  <FormLabel
                      sx={{
                        marginBottom: "8px",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "text.primary",
                      }}
                      required
                  >
                    {t("", "승인")}
                  </FormLabel>
                  <Controller
                      control={control}
                      name="approvalStatus"
                      rules={{
                        required: t("errors.required.field", { field: t("", "승인") },),
                      }}
                      // eslint-disable-next-line
                      defaultValue={null as any}
                      render={({ field }) => (
                          <Autocomplete
                              size="small"
                              {...field}
                              onChange={(
                                  _,
                                  value,
                              ) => {
                                field.onChange(
                                    value,
                                );
                              }}
                              options={["APRV_WAIT", "APRV_COMPT", "APRV_REJ"]}
                              getOptionLabel={(label) => t(`enum.admin.approvalStatus.${label}`)}
                              renderInput={(
                                  params,
                              ) => (
                                  <TextField
                                      {...params}
                                      variant="outlined"
                                      error={!!errors.approvalStatus}
                                      required
                                  />
                              )}
                          />
                      )}
                  />
                  {errors.approvalStatus && (
                      <FormHelperText error>{errors.approvalStatus.message}</FormHelperText>
                  )}
                </FormControl>
                <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                >
                  <SaveButton
                      {...saveButtonProps}
                      sx={{ width: 90 }}
                      loading={isLoading}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
      </>
  )
}