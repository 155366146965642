import {AuthPage, ThemedTitleV2} from "@refinedev/mui";
import {useTranslate} from "@refinedev/core";
import React from "react";

export const ForgotPassword = () => {
  const t = useTranslate();
  return (
      <AuthPage
          type="forgotPassword"
          title={
            <ThemedTitleV2
                collapsed={false}
                wrapperStyles={{
                  gap: "8px",
                }}
                text={t('', '녹사평 AR Bridge 관리자')} />
          }
      />
  );
};
