import React from "react";
import {getDefaultFilter, IResourceComponentsProps, useTable, useTranslate} from "@refinedev/core";
import Paper from "@mui/material/Paper";
import {Grid, InputBase, Pagination} from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {IContent} from "interfaces";
import IconButton from "@mui/material/IconButton";
import {Add, Search} from "@mui/icons-material";
import {CreateButton} from "@refinedev/mui";
import {ContentItem, DisplaySort} from "components/content";


export const ContentList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const {
    tableQueryResult,
    setFilters,
    filters,
    current,
    pageCount,
    setCurrent,
  } = useTable<IContent>({
    pagination: {
      pageSize: 12,
      mode: "client",
    },
    queryOptions: {
      retry: false
    }
  });

  const arContents: IContent[] = tableQueryResult.data?.data || [];

  return (
      <>
        <Paper
            sx={{
              paddingX: { xs: 3, md: 2 },
              paddingY: { xs: 2, md: 3 },
              my: 0.5,
            }}
        >
          <Grid container columns={16}>
            <Grid item
                  xs={16}
                // md={12}
            >
              <Stack
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  flexWrap="wrap"
                  padding={1}
                  direction="row"
                  gap={2}
              >
                <Typography variant="h5">{t("", "콘텐츠 관리")}</Typography>
                <Paper
                    component="form"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: 600,
                    }}
                >
                  <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder={t("", "검색")}
                      inputProps={{
                        "aria-label": "AR content search",
                      }}
                      value={getDefaultFilter(
                          "q",
                          filters,
                          "contains",
                      )}
                      onChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setFilters([
                          {
                            field: "q",
                            operator: "contains",
                            value:
                                e.target.value !== ""
                                    ? e.target.value
                                    : undefined,
                          },
                        ]);
                      }}
                  />
                  <IconButton
                      type="submit"
                      sx={{ p: "10px" }}
                      aria-label="search"
                  >
                    <Search />
                  </IconButton>
                </Paper>
                <Stack direction={"row"} gap={'10px'}>
                  <DisplaySort />
                  <CreateButton
                      startIcon={<Add />}
                      variant="outlined"
                      sx={{ marginBottom: "5px" }}
                  />
                </Stack>
              </Stack>
              <Grid container>
                {arContents.length > 0 ? (
                    arContents.map((content: IContent) => (
                        <Grid
                            item
                            xs={12}
                            md={6}
                            lg={4}
                            xl={3}
                            key={content.id}
                            sx={{ padding: "8px" }}
                        >
                          <ContentItem content={content} />
                        </Grid>
                    ))
                ) : (
                    <Grid
                        container
                        justifyContent="center"
                        padding={3}
                    >
                      <Typography variant="body2">
                        {t("", "저장된 콘텐츠가 없습니다.")}
                      </Typography>
                    </Grid>
                )}
              </Grid>
              <Pagination
                  count={pageCount}
                  page={current}
                  variant="outlined"
                  color="primary"
                  shape="rounded"
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    paddingY: "20px",
                  }}
                  onChange={(
                      event: React.ChangeEvent<unknown>,
                      page: number,
                  ) => {
                    event.preventDefault();
                    setCurrent(page);
                  }}
              />
            </Grid>
          </Grid>
        </Paper>
      </>
  )
}