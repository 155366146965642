import {AuthBindings} from "@refinedev/core";
import {axiosInstance} from "@refinedev/simple-rest";
import {API_URL, REGEX_PASSWORD, TOKEN_KEY} from "./constants";
import jwt_decode from "jwt-decode";

export const authProvider: AuthBindings = {
  login: async ({ username, email, password }) => {
    if ((username || email) && password) {

      const {data, status} = await axiosInstance.post(API_URL + '/admin/login', {
        email: email, password: password
      });

      if(status === 200 || status === 201) {
        localStorage.setItem(TOKEN_KEY, JSON.stringify(data));
        return {
          success: true,
          redirectTo: "/",
        };
      }
    }

    return {
      success: false,
      error: {
        name: "LoginError",
        message: "Invalid email or password",
      },
    };
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = JSON.parse(localStorage.getItem(TOKEN_KEY) ?? 'null');
    if (token) {
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token.access_token}`
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = JSON.parse(localStorage.getItem(TOKEN_KEY) ?? 'null');
    if (token) {
      const access_token: any = jwt_decode(token.access_token);

      return {
        id: access_token.id,
        email: access_token.email,
        name: access_token.name,
        avatar: token.avatar,
      };
    }
    return null;
  },
  onError: async (error) => {
    if (error.statusCode === 401) {
      return {
        logout: true,
        redirectTo: "/login",
        error: error || {
          message: error.message,
          name: "Session not found",
        },
      };
    }
    return { error };
  },
  register: async ( { email, password } ) => {
    if( email && password ) {

      if(password.match(REGEX_PASSWORD) === null) {
        return {
          success: false,
          error: {
            name: "Register Error",
            message: "비밀번호는 영문/숫자/특수기호(!@#$%^&*()) 포함 8~30자 입니다.",
          },
        };
      }

      const { status} = await axiosInstance.post(API_URL + '/admin', {
        email: email,
        name: email.split('@')[0],
        password: password
      });

      if(status === 200 || status === 201) {
        return {
          success: true,
          redirectTo: "/login",
        }
      }
    }

    return {
      success: false,
      error: {
        name: "Register Error",
        message: "Invalid email or password",
      },
    };
  },
};
